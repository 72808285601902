.cdk-overlay-container {
  z-index: 100;
}

.default-dialog__section {
  .base-dialog {
    z-index: 101;

    > div {
      height: 100%;
      min-height: fit-content;
      display: flex !important;

      .backdrop {
        background-color: transparent;
      }
    }

    .parent-game-dialog__section {
      height: 100vh;

      .game-dialog__section {
        .game-dialog {
          background-size: cover !important;
          background-repeat: no-repeat !important;
          background-position: bottom;
          width: 100vw;
          height: 100vh;
          padding-top: 2rem;
          padding-bottom: 10rem;
          overflow: hidden;

          .content-wrapper {
            width: 100%;

            .dialog-field-title {
              font-family: var(--main-font);
              font-size: 3rem;
              font-weight: bold;
              line-height: 1;
              color: #005aa0;
            }

            .dialog-field-text {
              width: 82vw;

              span {
                font-family: var(--main-font);
                color: #007acd;
                font-style: normal;
                font-weight: 600;
                font-size: 32px;
                line-height: normal;
                margin: auto;
              }

              &.vertical-align-center {
                display: flex;
                justify-content: center;
                margin: auto 0;
                height: 70%;
              }

              .text-italic {
                font-style: italic;
              }

              .text-underline {
                text-decoration: underline;
              }
            }

            .dialog-field-video {
              app-video-player {
                height: 100%;
              }
            }

            .dialog-field-button {
              margin-top: 0;
              border-radius: 10000px;
              background: #007acd;
              color: white;
              padding: 0.8rem 2.2rem;
              transition: all 0.4s ease;
              cursor: pointer;

              button {
                margin: 0;
                font-family: var(--main-font);
                font-size: 32px;
                font-weight: 500;
              }

              &:hover {
                background: #158de0;
              }
              &:active {
                background: #005aa0;
              }
            }

            .dialog-field-row {
              margin-top: 0;
              width: 100%;
              padding: 0 3%;

              .dialog-field-text {
                span {
                  font-size: 32px;
                }
              }
            }
          }
        }

        &.default-dialog {
          .content-wrapper {
            .dialog-field-text {
              &.text-final {
                span {
                  color: white;
                }
              }
            }

            .dialog-field-button {
              position: absolute;
              bottom: 12%;
            }
          }
        }

        &.dialog-image {
          .game-dialog {
            .content-wrapper {
              position: relative;

              .image {
                position: absolute;
                background-repeat: no-repeat;
                background-size: contain;
              }

              .dialog-field-button {
                bottom: 0%;
              }
            }
          }
        }
      }
      @media (max-width: 577px) {
        .game-dialog__section {
          .game-dialog {
            .content-wrapper {
              .dialog-field-text {
                span {
                  font-size: 16px;
                }
              }
              .dialog-field-button {
                padding: 0.6rem 2rem;
                button {
                  font-size: 16px;
                }
              }
            }
          }
        }
      }

      @media (max-width: 769px) {
        .game-dialog__section {
          .game-dialog {
            .content-wrapper {
              .dialog-field-text {
                span {
                  font-size: 20px;
                }
              }
              .dialog-field-button {
                padding: 0.6rem 2rem;
                button {
                  font-size: 20px;
                }
              }
            }
          }
        }
      }
      @media (min-width: 769px) and (max-width: 1280px) {
        .game-dialog__section {
          .game-dialog {
            padding-bottom: 8rem;
            .content-wrapper {
              .dialog-field-text {
                span {
                  font-size: 22px;
                }
              }
              .dialog-field-button {
                padding: 0.4rem 1.8rem;
                button {
                  font-size: 22px;
                }
              }
            }
          }
        }
      }
    }
  }
}
