.game-combination-lock__section {
  height: 100%;
  width: 100%;

  .config__section {
    height: 100%;
    display: flex;

    .password__group {
      position: relative;
      width: 100%;
      height: 100%;
      text-align: center;

      &:not(:last-child) {
        margin-right: 12px;
      }

      .password__container {
        height: 100%;
        overflow: hidden;

        .password {
          transform: translateY(-100%);

          &.move-transition {
            transition: transform 0.2s;
            -webkit-transition: transform 0.2s;
            --moz-transition: transform 0.2s;
            --ms-transition: transform 0.2s;
          }
        }

        .item {
          height: 100%;
          width: 100%;
          color: black;
          display: flex;
          justify-content: center;
          align-items: center;
          pointer-events: none;

          .item-img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .arrow {
        position: absolute;
        left: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        .custom-arrow {
          width: 24px;
          height: 24px;

          border: 1px solid black;
        }
      }

      .arrow-img {
        height: 100%;
        width: 100%;
      }

      .arrow-up {
        top: -8px;
        transform: translate(-50%, -100%);
      }

      .arrow-down {
        bottom: -8px;
        transform: translate(-50%, 100%);
      }
    }
  }

  &.horizontal {
    .config__section {
      flex-direction: column;

      .password__group {
        .arrow-up {
          transform: translate(-100%, -50%);
          left: -8px;
          top: 50%;
        }

        .arrow-down {
          top: 50%;
          left: auto;
          right: -8px;
          bottom: auto;
          transform: translate(100%, -50%);
        }

        .password__container {
          .password {
            transform: translateX(-100%);

            app-game-combination-lock-item {
              display: inline-block !important;
            }
          }
        }
      }
    }
  }
}
