html {
  /**
  * @see
  * normal button with text and icon
  * */

  // button variables
  /* --------- button small --------- */
  --button-sm-padding: 1rem 2rem;
  --button-sm-border-radius: 8px;

  --button-sm-font-size: 16px;
  --button-sm-font-weight: 500;
  --button-sm-line-height: 1;
  --button-sm-letter-spacing: 0.025em;
  --button-sm-text-transform: none;
  // button icon
  --icon-button-sm-size: 16px;

  /* --------- button medium --------- */
  --button-md-padding: 1rem 2rem;
  --button-md-border-radius: 8px;

  --button-md-font-size: 1.5rem;
  --button-md-font-weight: 600;
  --button-md-line-height: 1;
  --button-md-letter-spacing: 0.025em;
  --button-md-text-transform: none;

  --icon-button-md-size: 16px;

  /* --------- button primary --------- */
  --button-primary-color: var(--blue-standard-light);
  --button-primary-background: #fff;
  --button-primary-border: none;
  --button-primary-outline: none;

  --icon-button-primary-color: var(--blue-standard-light);

  /* --------- button primary hover --------- */
  --button-primary-hover-color: var(--blue-standard-light);
  --button-primary-hover-background: var(--blue-lightest);
  --button-primary-hover-border: none;
  --button-primary-hover-outline: none;

  --icon-button-primary-hover-color: var(--blue-standard-light);

  /* --------- button primary focus --------- */
  --button-primary-focus-color: var(--blue-standard-light);
  --button-primary-focus-background: var(--blue-light);
  --button-primary-focus-border: none;
  --button-primary-focus-outline: none;

  --icon-button-primary-focus-color: var(--blue-standard-light);

  /* --------- button primary active --------- */
  --button-primary-active-color: var(--blue-standard-light);
  --button-primary-active-background: var(--blue-light);
  --button-primary-active-border: none;
  --button-primary-active-outline: none;

  --icon-button-primary-active-color: var(--blue-standard-light);

  /* --------- button primary disabled --------- */
  --button-primary-disabled-color: #fff;
  --button-primary-disabled-background: var(--grey-dark);
  --button-primary-disabled-border: none;
  --button-primary-disabled-outline: none;

  --icon-button-primary-disabled-color: #ffffff;

  /* --------- button secondary --------- */
  --button-secondary-color: #fff;
  --button-secondary-background: var(--blue-standard-light);
  --button-secondary-border: none;
  --button-secondary-outline: none;

  --icon-button-secondary-color: #344054;

  /* --------- button secondary hover --------- */
  --button-secondary-hover-color: #fff;
  --button-secondary-hover-background: var(--blue-light);
  --button-secondary-hover-border: none;
  --button-secondary-hover-outline: none;

  --icon-button-secondary-hover-color: #fff;

  /* --------- button secondary focus --------- */
  --button-secondary-focus-color: #fff;
  --button-secondary-focus-background: var(--blue-dark);
  --button-secondary-focus-border: none;
  --button-secondary-focus-outline: none;

  --icon-button-secondary-focus-color: #fff;

  /* --------- button secondary active --------- */
  --button-secondary-active-color: #fff;
  --button-secondary-active-background: var(--blue-dark);
  --button-secondary-active-border: none;
  --button-secondary-active-outline: none;

  --icon-button-secondary-active-color: #fff;

  /* --------- button secondary disabled --------- */
  --button-secondary-disabled-color: #fff;
  --button-secondary-disabled-background: var(--grey-dark);
  --button-secondary-disabled-border: none;
  --button-secondary-disabled-outline: none;

  --icon-button-secondary-disabled-color: #fff;

  /*
  * @see button with icon only
  */

  /* --------- button icon small --------- */
  --button-icon-sm-padding: 0.25rem;
  --button-icon-sm-size: 1.25rem;

  /* --------- button icon medium --------- */
  --button-icon-md-padding: 1rem;
  --button-icon-md-size: 4rem;

  /* --------- button icon primary --------- */
  --button-icon-primary-color: #ffffff;
  --button-icon-primary-background: #344054;
  --button-icon-primary-border: 1px solid transparent;
  --button-icon-primary-outline: 2px solid transparent;

  /* --------- button icon primary hover --------- */
  --button-icon-primary-hover-color: #ffffff;
  --button-icon-primary-hover-background: #475467;
  --button-icon-primary-hover-border: 1px solid transparent;
  --button-icon-primary-hover-outline: 2px solid transparent;

  /* --------- button icon primary focus --------- */
  --button-icon-primary-focus-color: #fff;
  --button-icon-primary-focus-background: #667085;
  --button-icon-primary-focus-border: 1px solid transparent;
  --button-icon-primary-focus-outline: 2px solid transparent;

  /* --------- button icon primary active --------- */
  --button-icon-primary-active-color: #fff;
  --button-icon-primary-active-background: #667085;
  --button-icon-primary-active-border: 1px solid transparent;
  --button-icon-primary-active-outline: 2px solid transparent;

  /* --------- button icon primary disabled --------- */
  --button-icon-primary-disabled-color: #fff;
  --button-icon-primary-disabled-background: #98a2b3;
  --button-icon-primary-disabled-border: 1px solid transparent;
  --button-icon-primary-disabled-outline: 2px solid transparent;

  /* --------- button icon secondary --------- */
  --button-icon-secondary-color: #344054;
  --button-icon-secondary-background: #fff;
  --button-icon-secondary-border: 1px solid #344054;
  --button-icon-secondary-outline: 2px solid transparent;

  /* --------- button icon secondary hover --------- */
  --button-icon-secondary-hover-color: #344054;
  --button-icon-secondary-hover-background: #f2f4f7;
  --button-icon-secondary-hover-border: 1px solid #344054;
  --button-icon-secondary-hover-outline: 2px solid transparent;

  /* --------- button icon secondary focus --------- */
  --button-icon-secondary-focus-color: #344054;
  --button-icon-secondary-focus-background: #d0d5dd;
  --button-icon-secondary-focus-border: 1px solid #344054;
  --button-icon-secondary-focus-outline: 2px solid transparent;

  /* --------- button icon secondary active --------- */
  --button-icon-secondary-active-color: #344054;
  --button-icon-secondary-active-background: #d0d5dd;
  --button-icon-secondary-active-border: 1px solid #344054;
  --button-icon-secondary-active-outline: 2px solid transparent;

  /* --------- button icon secondary disabled --------- */
  --button-icon-secondary-disabled-color: #98a2b3;
  --button-icon-secondary-disabled-background: #fff;
  --button-icon-secondary-disabled-border: 1px solid #344054;
  --button-icon-secondary-disabled-outline: 2px solid transparent;
}
