emeraude-sso-login {
  @media (min-width: 640px) {
    .body__content {
      .content {
        width: 656px !important;
      }
    }
  }
}

emeraude-select-episode {
  .body__content {
    .content {
      .title {
        h1 {
          font-family: 'Origin Tech Demo', sans-serif;
          font-size: 5rem !important;
          line-height: normal !important;
          font-weight: 400 !important;
        }
      }
    }
  }
}

.color-emeraude {
  color: var(--blue-standard-light);
}

emeraude-select-episode {
  .content__full {
    h5 {
      margin-top: 1rem !important;
      margin-bottom: 2.875rem !important;
    }

    .card__title {
      h5 {
        margin-top: 0.75rem !important;
        margin-bottom: 0 !important;
      }
    }
  }
}
