html {
  /**
  * title
  * scope is global
  * */
  --title-primary-font-size: 5rem;
  --title-primary-font-weight: 400;
  --title-primary-line-height: 1;
  --title-primary-letter-spacing: 0;
  --title-primary-color: #fff;

  --title-secondary-font-size: 3rem;
  --title-secondary-font-weight: 800;
  --title-secondary-line-height: 1;
  --title-secondary-letter-spacing: 0.025em;
  --title-secondary-color: #fff;

  --title-tertiary-font-size: 2rem;
  --title-tertiary-font-weight: 600;
  --title-tertiary-line-height: 1;
  --title-tertiary-letter-spacing: 0.025em;
  --title-tertiary-color: #fff;

  /**
  * subtitle
  * scope is global
  * */
  --subtitle-primary-font-size: 1.5rem;
  --subtitle-primary-font-weight: 500;
  --subtitle-primary-line-height: 1;
  --subtitle-primary-letter-spacing: 0.025em;
  --subtitle-primary-color: #fff;

  --subtitle-secondary-font-size: 1.5rem;
  --subtitle-secondary-font-weight: 600;
  --subtitle-secondary-line-height: 1;
  --subtitle-secondary-letter-spacing: 0.025em;
  --subtitle-secondary-color: #fff;

  /**
  * text
  * scope is global
  * */
  --text-primary-font-size: 1rem;
  --text-primary-font-weight: 400;
  --text-primary-line-height: 1.2;
  --text-primary-letter-spacing: 0.025em;
  --text-primary-color: #fff;

  --text-secondary-font-size: 1rem;
  --text-secondary-font-weight: 600;
  --text-secondary-line-height: 1.2;
  --text-secondary-letter-spacing: 0.025em;
  --text-secondary-color: #fff;

  /**
  * link
  * scope is global
  * */
  --link-font-size: 0.875rem;
  --link-font-weight: 500;
  --link-line-height: 1;
  --link-letter-spacing: 0.025em;
  --link-color: #fff;

  /**
  * text score component
  * scope is global
  * */
  --text-score-title-font-size: 1.5rem;
  --text-score-title-font-weight: 400;
  --text-score-title-line-height: 1;
  --text-score-title-letter-spacing: 0.025em;
  --text-score-title-color: #fff;

  --text-score-primary-font-size: 1.5rem;
  --text-score-primary-font-weight: 600;
  --text-score-primary-line-height: 1;
  --text-score-primary-letter-spacing: 0.025em;
  --text-score-primary-color: #fff;

  --text-score-secondary-font-size: 1.5rem;
  --text-score-secondary-font-weight: 700;
  --text-score-secondary-line-height: 1;
  --text-score-secondary-letter-spacing: 0.025em;
  --text-score-secondary-color: #fff;

  /**
  * text footer component
  * scope is global
  * */
  --text-footer-font-size: 12.96px;
  --text-footer-font-weight: 500;
  --text-footer-line-height: 1;
  --text-footer-letter-spacing: 0.025em;
  --text-footer-color: #fff;
}
