.cdk-overlay-pane {
  pointer-events: none;
}
.mdc-snackbar {
  &.mat-mdc-snack-bar-container {
    margin: 40px;
    &.mdc-snackbar--open {
      &.alert-error {
        .mdc-snackbar__surface {
          pointer-events: none;
          border-radius: 1000px;
          background: var(--white-100, #fff);
          border: 0.5px solid #db3735;
          text-align: center;
          color: #db3735;
          padding: 0.5rem;
          width: 748px;
          font-size: 1.5rem;
        }
      }

      &.alert-success {
        .mdc-snackbar__surface {
          pointer-events: none;
          border-radius: 1000px;
          border: 0.5px solid #23d2b5;
          background: var(--blue-bolt, #007acd);
          text-align: center;
          color: #fff;
          padding: 0.5rem;
          width: 748px;
          font-size: 1.5rem;
        }
      }
    }

    @media screen and (max-width: 959.98px) and (orientation: landscape) {
      margin: 20px;
      &.mdc-snackbar--open {
        &.alert-error,
        &.alert-success {
          .mdc-snackbar__surface {
            transform: scale(0.7);
          }
        }
      }
    }
  }
}
