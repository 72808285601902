@import '~@angular/cdk/overlay-prebuilt.css';
@import 'video.js/dist/video-js.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@import '../src/app/shared/asset/scss/font';
@import '../src/app/shared/asset/scss/dialog';
@import '../src/app/shared/asset/scss/override';
@import '../src/app/shared/asset/scss/text';
@import '../src/app/shared/asset/scss/button';

@import '../src/app/shared/asset/scss/_mat-snackbar';
@import '../src/app/shared/asset/scss/_game-combination-lock';

/* You can add global styles to this file, and also import other style files */

* {
  touch-action: manipulation;
}

body,
html {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
  user-select: none;
}

.d--none {
  display: none;
}

.hidden {
  pointer-events: none;
  opacity: 0;
  user-select: none;
}

.drag-scroll-content::-webkit-scrollbar {
  display: none;
}

.clickable {
  cursor: pointer;
  user-select: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

@layer base {
  html {
    font-family: Roboto, sans-serif;

    --main-font: Roboto, sans-serif;
    --secondary-font: Lato, sans-serif;
    --game-title-font: 'Origin Tech Demo', sans-serif;

    --blue-standard-light: rgb(28, 179, 255);
    --blue-dark: rgb(68, 94, 141);
    --blue-light: rgb(169, 199, 229);
    --blue-lightest: rgb(211, 224, 238);
    --blue-bolt: rgb(28, 179, 255);
    --blue-bolt-darker: rgb(0, 90, 160);
    --color-additional-purple: rgb(85, 60, 176);
    --color-additional-green: rgb(200, 215, 44);
    --color-white: rgb(255, 255, 255);
    --color-black: rgb(0, 0, 0);
    --grey-700: rgb(67, 92, 110);
    --grey-100: rgb(242, 244, 247);
    --grey-dark: rgb(99, 100, 100);
    --yellow: rgba(255, 204, 89, 1);
    --bg-gradient: linear-gradient(135deg, #23d2b5 0%, #00bcfd 100%);
    --color-grey-700: rgb(67, 92, 110);
    --color-grey-100: rgb(242, 244, 247);
    --color-grey-500: rgba(24, 38, 99, 1);
    --color-grey-400: rgba(0, 122, 205, 0.48);

    --blue-active: #9cc3e1;

    --text-title: rgb(255, 255, 255);
    --text-subtitle: rgb(255, 255, 255);
    --text-footer: rgb(255, 255, 255);

    --fs-xs: 0.5rem;
    --fs-sm: 0.75rem;
    --fs-label: 0.875rem;
    --fs-base: 1rem;
    --fs-lg: 1.25rem;
    --fs-xl: 1.5rem;
    --fs-2xl: 2rem;
    --fs-3xl: 2.5rem;
    --fs-4xl: 3rem;
    --fs-5xl: 3.5rem;
    --fs-title: 5rem;

    --br-xs: 0.125rem;
    --br-sm: 0.25rem;
    --br-base: 0.5rem;
    --br-lg: 0.75rem;
    --br-card-sm: 0.75rem;
    --br-card-md: 1.5rem;
    --br-rounded: 50%;
    --br-pill: 1000px;

    --bw-0: 0;
  }
}

// html {
//   --blue-bolt: '0, 122, 205',
//   --blue-bolt-darker: '0, 90, 160',
//   --color-white: '255, 255, 255',
//   --color-black: '0, 0, 0',
//   --bg-gradient: 'linear-gradient(135deg, #23D2B5 0%, #00BCFD 100%);',
//   --grey-700: '67, 92, 110',
//   --grey-100: '242, 244, 247',
//   --yellow: '255, 204, 89, 1',
// }
